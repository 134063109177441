<template>
  <div class="mx-5">
    <v-breadcrumbs
      :items="[{ text: 'ΠΔΕ' }, { text: 'ΕΡΓΑ' }]"
      divider=">"
    ></v-breadcrumbs>

    <v-card class="pa-4">
      <div class="d-flex justify-space-between my-10 mx-5">
        <v-btn
          tile
          large
          color="primary darken-2"
          @click="$router.push('/pde/new-project')"
        >
          Νεο εργο
          <v-icon right>mdi-plus</v-icon>
        </v-btn>

        <div class="d-flex align-center">
          <v-text-field
            outlined
            rounded
            dense
            v-model="filters.query"
            class="no-message mr-8"
            label="Αναζήτηση"
            append-outer-icon="mdi-magnify"
            @click:append-outer="getPdeJobs"
            @keyup.enter="getPdeJobs"
          >
            <template #append>
              <v-icon
                right
                v-if="filters.query"
                @click="
                  filters.query = null;
                  getPdeJobs();
                "
              >
                mdi-close
              </v-icon>
            </template>
          </v-text-field>

          <v-menu
            left
            offset-y
            nudge-bottom="5"
            min-width="550px"
            max-width="550px"
            :close-on-content-click="false"
          >
            <template #activator="{ on }">
              <v-btn large class="elevation-1" color="grey lighten-4" v-on="on">
                <v-icon left>mdi-filter-outline</v-icon>
                Φιλτρα
                <v-icon right>mdi-chevron-down</v-icon>
              </v-btn>
            </template>

            <v-form class="white pa-4 pb-6">
              <v-row dense justify="space-between">
                <v-btn text :disabled="!anyFilterApplied" @click="resetFilters">
                  Εκκαθαριση
                  <v-icon right>mdi-filter-off-outline</v-icon>
                </v-btn>
              </v-row>

              <v-row dense>
                <v-col cols="6">
                  <v-autocomplete
                    clearable
                    label="Περιφερειακή ενότητα"
                    v-model="filters.unit"
                    item-value="id"
                    item-text="name"
                    :items="filtersLists.units"
                  ></v-autocomplete>
                </v-col>

                <v-col cols="6">
                  <v-autocomplete
                    clearable
                    label="Δήμος"
                    v-model="filters.municipality"
                    item-value="id"
                    item-text="name"
                    :items="filtersLists.municipalities"
                  ></v-autocomplete>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="6">
                  <v-autocomplete
                    clearable
                    label="Είδος έργου"
                    v-model="filters.jobType"
                    item-value="id"
                    item-text="name"
                    :items="filtersLists.jobTypes"
                  ></v-autocomplete>
                </v-col>

                <v-col cols="6">
                  <v-autocomplete
                    clearable
                    label="Υποτομέας"
                    v-model="filters.pdeSubsector"
                    item-value="id"
                    item-text="name"
                    :items="filtersLists.subsectors"
                  ></v-autocomplete>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="6">
                  <v-autocomplete
                    clearable
                    label="Φάση έργου"
                    v-model="filters.jobPhase"
                    item-value="id"
                    item-text="name"
                    :items="filtersLists.jobPhases"
                  ></v-autocomplete>
                </v-col>

                <v-col cols="6">
                  <v-autocomplete
                    clearable
                    label="Φάση έργου αρχή έτους"
                    v-model="filters.newYearJobPhase"
                    item-value="id"
                    item-text="name"
                    :items="filtersLists.jobPhases"
                  ></v-autocomplete>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="6">
                  <v-autocomplete
                    clearable
                    label="Απόφαση"
                    v-model="filters.pdeDecision"
                    item-value="id"
                    item-text="name"
                    :items="filtersLists.decisions"
                  ></v-autocomplete>
                </v-col>

                <v-col cols="6">
                  <v-text-field
                    clearable
                    type="number"
                    label="Έτος"
                    v-model="filters.year"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row dense>
                <v-btn
                  text
                  outlined
                  class="mx-auto"
                  color="primary"
                  :loading="pdeJobsListLoader"
                  @click="getPdeJobs"
                >
                  Αναζητηση
                </v-btn>
              </v-row>
            </v-form>
          </v-menu>
        </div>
      </div>

      <v-data-table
        dense
        hide-default-footer
        no-data-text="Δεν Βρέθηκαν 'Εργα"
        :headers="headers"
        :items="pdeJobsList"
        :loading="pdeJobsListLoader"
      >
        <template #[`item.show`]="{ item }">
          <v-tooltip top>
            <template #activator="{ on }">
              <v-btn
                icon
                v-on="on"
                @click="$router.push(`/pde/projects/${item.id}`)"
              >
                <v-icon small>mdi-open-in-app</v-icon>
              </v-btn>
            </template>
            Προβολή
          </v-tooltip>
        </template>

        <template #footer>
          <div class="d-flex align-center py-2">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  icon
                  v-on="on"
                  :disabled="listPage == 1"
                  @click="
                    listPage -= 1;
                    getPdeJobs();
                  "
                >
                  <v-icon>mdi-arrow-left</v-icon>
                </v-btn>
              </template>
              <span>Προηγούμενη Σελίδα</span>
            </v-tooltip>

            <span class="mx-auto">
              Σελίδα {{ listPage }} από {{ listPagesCount }}
            </span>

            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  icon
                  v-on="on"
                  :disabled="listPage == listPagesCount"
                  @click="
                    listPage += 1;
                    getPdeJobs();
                  "
                >
                  <v-icon>mdi-arrow-right</v-icon>
                </v-btn>
              </template>
              <span>Επόμενη Σελίδα</span>
            </v-tooltip>
          </div>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      filtersLists: {
        jobPhases: [],
        jobTypes: [],
        decisions: [],
        subsectors: [],
        municipalities: [],
        units: [],
      },
      filters: {
        query: null,
        municipality: null,
        jobType: null,
        jobPhase: null,
        newYearJobPhase: null,
        pdeDecision: null,
        pdeSubsector: null,
        unit: null,
        year: null,
      },
      listPage: 1,
      listPagesCount: null,
      headers: [
        {
          value: "show",
          sortable: false,
        },
        {
          text: "Τίτλος",
          value: "title",
        },
        {
          text: "Δήμος",
          value: "municipality.name",
        },
        {
          text: "Περιφερειακή ενότητα",
          value: "unit.name",
        },
        {
          text: "Κωδικός περιφερειακής ενότητας/δήμος",
          value: "uniMunicipality.code",
        },
        {
          text: "Προϋπολογισμός",
          value: "budget",
        },
        {
          text: "Απόφαση",
          value: "pdeDecision.name",
          width: "105px",
        },
        {
          text: "Αριθμός πρωτοκόλλου απόφασης ",
          value: "pdeDecisionProtocolNumber",
        },
        {
          text: "Ημερομηνία αριθμού πρωτοκόλλου απόφασης",
          value: "pdeDecisionProtocolNumberDate",
        },
        {
          text: "Υποτομέας",
          value: "pdeSubsector.name",
          width: "115px",
        },
        {
          text: "Κωδικός ΣΑΕΠ",
          value: "saepCode",
        },
        {
          text: "Κωδικός τράπεζας",
          value: "bankCode",
        },
      ],
      pdeJobsList: [],
      currentYear: new Date().getFullYear(),
      pdeJobsListLoader: false,
    };
  },
  computed: {
    anyFilterApplied() {
      for (let key in this.filters) {
        if (this.filters[key]) return true;
      }
      return false;
    },
  },
  methods: {
    async getPdeJobs() {
      this.pdeJobsListLoader = true;
      try {
        let res;
        if (this.anyFilterApplied) {
          res = await axios("/api/search/pde/job", {
            params: { ...this.filters },
          });

          this.pdeJobsList = res.data.data.documents;
        } else {
          res = await axios("/api/pde/job/list/paging", {
            params: {
              page: this.listPage,
            },
          });

          this.pdeJobsList = res.data.data;
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.pdeJobsListLoader = false;
      }
    },
    resetFilters() {
      this.filters.query = "";
      this.filters.municipality = null;
      this.filters.jobType = null;
      this.filters.jobPhase = null;
      this.filters.newYearJobPhase = null;
      this.filters.pdeDecision = null;
      this.filters.pdeSubsector = null;
      this.filters.unit = null;
      this.filters.year = null;
      this.getPdeJobs();
    },
  },
  async created() {
    this.pdeJobsListLoader = true;
    try {
      this.getPdeJobs();

      // set paging counter
      let res = await axios("/api/pde/job/list/counter");
      this.listPagesCount = Math.ceil(res.data.data / 20);

      // get filters lists
      let endpoints = [
        "/api/pde/decision/list",
        "/api/pde/subsector/list",
        "/api/unit/list",
        "/api/municipality/list",
        "/api/job/type/list",
        "/api/job/phase/list",
      ];
      let [
        { data: decisionRes },
        { data: subsectorRes },
        { data: unitRes },
        { data: municipalityRes },
        { data: jobTypeRes },
        { data: jobPhaseRes },
      ] = await Promise.all(endpoints.map((endpoint) => axios(endpoint)));

      this.filtersLists.decisions = decisionRes.data;
      this.filtersLists.subsectors = subsectorRes.data;
      this.filtersLists.units = unitRes.data;
      this.filtersLists.municipalities = municipalityRes.data;
      this.filtersLists.jobTypes = jobTypeRes.data;
      this.filtersLists.jobPhases = jobPhaseRes.data;
    } catch (err) {
      console.log(err);
    } finally {
      this.pdeJobsListLoader = false;
    }
  },
};
</script>

<style lang="scss" scoped></style>
